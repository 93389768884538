<template>
  <div class="mailbox">
    <div class="centerbox" v-show="!isdetail">
      <!-- 列表 -->
      <div class="listbox">
        <div class="title">邮件中心</div>
        <div class="typeitembox">
          <div
            class="typeitem point"
            v-for="item in typelist"
            :class="{ actypeitem: typeindex == item.id }"
            :key="item.id"
            @click="changetype(item.id)"
          >
            {{ item.title }}
            <div class="alltips" v-if="item.unread_count > 0">
              {{ item.unread_count }}
            </div>
          </div>
          <div class="OneClick">
            <div class="onebox" @click="allread">
              <img src="../../assets/images/mall/read.png" alt="" />一键已读
            </div>
            <!-- <div class="onebox" @click="deleteAll">
              <img src="../../assets/images/mall/delete.png" alt="" />一键删除
            </div> -->
            <div class="onebox" @click="deleteclick" v-show="!deleteshow">
              <img src="../../assets/images/mall/delete.png" alt="" />批量删除
            </div>
            <div class="onebox" v-show="deleteshow" @click="deletemsg">
              <img src="../../assets/images/mall/delete.png" alt="" />确认删除
            </div>
            <!-- <div class="onebox" v-show="deleteshow" @click="toggleSelectAll">
              <img :src="isAllSelected ? require('../../assets/images/mall/click.png') : require('../../assets/images/mall/noclick.png')" alt="" />
              {{ isAllSelected ? '取消全选' : '全选' }}
            </div> -->
          </div>
        </div>
        <div class="maillist" id="mailListId" @scroll="scroll">
          <div class="listitem point" v-for="item in items" :key="item.id">
            <div
              class="tipsicon"
              v-show="item.is_read == 1 && !deleteshow"
            ></div>
            <div
              class="checkbox point"
              v-show="deleteshow && item.is_read !== 1"
              @click="tocheck(item.id)"
            >
              <img
                :src="
                  chekindex.includes(item.id)
                    ? require('../../assets/images/mall/click.png')
                    : require('../../assets/images/mall/noclick.png')
                "
                alt=""
              />
            </div>
            <div class="listcenter" @click="godetail(item)">
              <div class="listtitle">
                {{ item.class_name }}
              </div>
              <div class="listcontent elips-1">
                {{ item.message }}
              </div>
              <div class="listtime">{{ item.create_time }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isdetail">
      <Maildetail :item="detailitem" @bankclick="banktype" />
    </div>
  </div>
</template>

<script>
import {
  getmestitle,
  getmeslist,
  readmsgApi,
  deletemsgApi,
  allreadmsgApi,
} from "../../network/api";
import Maildetail from "./components/Maildetail.vue";
export default {
  components: { Maildetail },
  data() {
    return {
      isdetail: false,
      typelist: [],
      typeindex: 1,
      chekindex: [], // 存储选中项的id
      isAllSelected: false, // 是否全选
      deleteshow: false,
      items: [],
      page: 1,
      detailitem: {},
      isScrollFetch: false, // 用户是否已经滑动需要请求后续数据
    };
  },

  created() {
    this.refreshData();
    this.gettypelist();
  },
  watch: {
    isScrollFetch(newVal) {
      if (newVal) {
        this.page += 1;
        this.fetchData();
      }
    },
  },
  methods: {
    scroll(e) {
      // 获取 div 元素
      const myDiv = document.getElementById("mailListId");
      // 获取垂直滚动距离
      const scrollTop = myDiv.scrollTop;
      if (scrollTop > 500) {
        this.isScrollFetch = true;
      }
    },
    async allread() {
      const params = {
        class: this.typeindex,
      };
      const res = await allreadmsgApi(params);
      if (res.data.code == 200) {
        this.$message.success("阅读成功");
      }
      this.gettypelist();
      this.refreshData();
    },
    async deletemsg() {
      let params = {
        ids: this.chekindex.join(),
      };
      const res = await deletemsgApi(params);
      this.deleteshow = !this.deleteshow;
      if (res.data.code == 200) {
        this.$message.success("删除成功");
      }
      this.chekindex = [];
      this.refreshData();
    },
    banktype(v) {
      this.isdetail = !v;
      this.refreshData();
      this.gettypelist();
    },
    tocheck(v) {
      const index = this.chekindex.indexOf(v);
      if (index > -1) {
        this.chekindex.splice(index, 1);
      } else {
        this.chekindex.push(v);
      }
      this.isAllSelected =
        this.chekindex.length ===
        this.items.filter((item) => item.is_read !== 1).length;
    },
    toggleSelectAll() {
      this.isAllSelected = !this.isAllSelected;
      if (this.isAllSelected) {
        this.chekindex = this.items
          .filter((item) => item.is_read !== 1)
          .map((item) => item.id);
      } else {
        this.chekindex = [];
      }
    },
    godetail(v) {
      this.detailitem = v;
      let params = {
        id: v.id,
      };
      readmsgApi(params).then((res) => {});
      this.isdetail = true;
      this.deleteshow = false;
    },
    async gettypelist() {
      const res = await getmestitle();
      this.typelist = res.data.data.class;
    },
    async fetchData() {
      try {
        let parmes = {
          class: this.typeindex,
          page: this.page,
        };
        const res = await getmeslist(parmes);
        if (res.data.code === 200) {
          this.items = [...this.items, ...res.data.data.list];
          if (res.data.data.list.length === 20) {
            if (this.isScrollFetch) {
              this.page += 1;
              await this.fetchData();
            }
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async refreshData() {
      this.page = 1;
      this.items = [];
      this.fetchData();
    },
    deleteclick() {
      this.deleteshow = !this.deleteshow;
    },
    changetype(v) {
      this.deleteshow = false;
      this.isScrollFetch = false;
      this.chekindex = [];
      this.typeindex = v;
      this.refreshData();
    },
  },
};
</script>

<style lang="scss" scoped>
.mailbox {
  width: 100%;
  min-height: 5rem;
  padding: 0.45rem 0;
  .centerbox {
    width: 15rem;
    height: 11.8rem;
    background: url("../../assets/images/NewUser/pc-nuserbg1.png") no-repeat;
    background-size: 100% 100%;
    margin: 0 auto;
    .title {
      height: 1rem;
      font-weight: 600;
      font-size: 0.32rem;
      color: #8a8aca;
      text-align: center;
      line-height: 1rem;
    }
    .listbox {
      width: 13.64rem;
      margin: 0 auto;
      .typeitembox {
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        .typeitem {
          width: 1.44rem;
          height: 0.44rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #020715;
          border: 0.02rem solid #2f2f5e;
          margin-right: 0.15rem;
          font-weight: 400;
          font-size: 0.18rem;
          color: #dedede;
          position: relative;
        }
        .alltips {
          position: absolute;
          top: 0;
          right: 0;
          background: #ff7300;
          padding: 0.02rem 0.05rem;
          border-radius: 0.16rem;
          font-weight: 500;
          font-size: 0.12rem;
          color: #ffffff;
        }
        .actypeitem {
          background: linear-gradient(95deg, #2f2f5e 0%, #222242 100%);
          color: #fff;
          border: 0.02rem solid #6565a6;
        }
        .OneClick {
          position: absolute;
          right: 0;
          display: flex;
          align-items: center;
          .onebox {
            width: 1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 0.5rem;
            font-weight: 400;
            font-size: 0.18rem;
            color: #c5bbed;
            margin-left: 0.25rem;
            cursor: pointer;
            img {
              width: 0.18rem;
              height: 0.18rem;
            }
          }
        }
      }

      .maillist {
        width: 100%;
        height: 9.5rem;
        overflow-y: scroll;
        margin-top: 0.24rem;
        background: #08080f;
        border: 0.02rem solid #2f2f5e;
        border-radius: 0.06rem;
        .listitem {
          width: 13.2rem;
          height: 1.25rem;
          background: #101021;
          border-radius: 0.02rem;
          border: 0.02rem solid #2f2f5e;
          margin: 0.18rem auto;
          position: relative;
          .listcenter {
            width: 12.84rem;
            margin: 0.18rem auto;
            .listtitle {
              font-weight: 500;
              font-size: 0.18rem;
              color: #b0b0e7;
            }
            .listcontent {
              width: 100%;
              font-weight: 400;
              font-size: 0.16rem;
              color: #b0b0e7;
              margin: 0.1rem 0;
            }
            .listtime {
              width: 100%;
              display: flex;
              justify-content: flex-end;
              font-weight: 400;
              font-size: 0.16rem;
              color: #77779b;
            }
          }
          .tipsicon {
            position: absolute;
            right: 0.2rem;
            top: 0.2rem;
            width: 0.08rem;
            height: 0.08rem;
            border-radius: 50%;
            background: #ff7300;
          }
          .checkbox {
            z-index: 2;
            position: absolute;
            right: 0.2rem;
            top: 0.2rem;
            img {
              width: 0.18rem;
              height: 0.18rem;
            }
          }
        }
      }
      /* 对于 WebKit 浏览器（如 Chrome 和 Safari） */
      .maillist::-webkit-scrollbar {
        display: none; /* 隐藏滚动条 */
      }

      /* 对于 Firefox 浏览器 */
      .maillist {
        scrollbar-width: none; /* 隐藏滚动条 */
      }

      /* 对于 IE 和 Edge 浏览器 */
      .maillist {
        -ms-overflow-style: none; /* 隐藏滚动条 */
      }
    }
  }
  .refresh,
  .load-more {
    text-align: center;
    padding: 0.1rem;
  }
}
</style>
